
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Injectable({ providedIn: 'root'})

export class InterceptorInterceptor implements HttpInterceptor { 
  constructor(private router: Router, private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
    const token = JSON.parse(localStorage.getItem("user"));

    // Attach the token to the request headers for POST and PUT requests using FormData
  if ((req.method === 'POST' || req.method === 'PUT'  || req.method === 'PATCH') && req.body instanceof FormData) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": '*',

      }
    });
  }

  // Add all headers to requests that don't use FormData
  if (!(req.body instanceof FormData)) {
    req = req.clone({
      setHeaders: {
    
                   Authorization:`Bearer ${token}`,
                   "Access-Control-Allow-Origin": '*',
                 "Content-Type": "application/json",
                 "Accept":"application/json",
                  'Access-Control-Allow-Methods': '*' ,
          
      }
    });
  }

  return next.handle(req);
}

            
            }


           