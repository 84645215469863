import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },




  {
    path: "country",
    loadChildren: () => import("../../components/apps/country/country.module").then((m) => m.CountryModule),
  },

  {
    path: "chat",
    loadChildren: () => import("../../components/apps/chat/chat.module").then((m) => m.ChatModule),
  },
  {
    path: "cms",
    loadChildren: () => import("../../components/apps/cms/cms.module").then((m) => m.CmsModule),
  },


  {
    path: "product",
    loadChildren: () => import("../../components/apps/product-management/e-commerce.module").then((m) => m.ECommerceModule),
  },

  {
    path: "order",
    loadChildren: () => import("../../components/apps/order-management/order-management.module").then((m) => m.OrderManagementModule),
  },
  {
    path: "user",
    loadChildren: () => import("../../components/apps/users/users.module").then((m) => m.UsersModule),
  },
 





];
