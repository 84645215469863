<div class="container-fluid">
  <div class="row">
    <div class="col-xl-7" [ngStyle]="{'background-image': 'url(assets/images/login/2.jpg)',
                                     'background-size': 'cover',
                                     'background-position': 'center center',
                                     'display': 'block'}">
      <img class="bg-img-cover bg-center d-none" src="assets/images/login/2.jpg" alt="looginpage">
    </div>
    <div class="col-xl-5 p-0">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="looginpage" />
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Sign in to account</h4>
              <p>Enter your email & password to login</p>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com" formControlName="email" />
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">Email is required</div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email" class="text text-danger mt-1">Invalid Email</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********" />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">Password is required</div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" formControlName="remember" value="true" type="checkbox" />
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div>
                <a [routerLink]="'/auth/forgot-password'" class="link">Forgot password?</a>
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid" (click)="login()" type="button"><span>Sign in</span></button>
                </div>
              </div>
          
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>