import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Admin } from '../model/admin'; 
import {EncryptionService} from './encryption.service'
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userSubject: BehaviorSubject<Admin | string>;
  public user: Observable<Admin | string>;
  constructor(public http:HttpClient ,
    
    private encryptionService: EncryptionService) {

    this.userSubject = new BehaviorSubject<any>(localStorage.getItem('user'));
    this.user = this.userSubject.asObservable();
   }

  baseURL='https://api.caistt.com/api/v1/';

isUserLoggedIn$ = new BehaviorSubject<boolean>(this.loggedIn$());
loggedIn$() { if (localStorage.getItem("user")) { return true } else { return false } }

headers=new HttpHeaders()
.set('content-type', 'application/json')
.set('Access-Control-Allow-Origin',`*`)
.set('Accept','application/json')
.set( 'Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS' )


LoginUser(user: Admin): Observable<any>{  
  const body=JSON.stringify(user);
   return this.http.post(`${this.baseURL}auth/login`, body,)

   
 }


getAdminInfo():Observable<any>{
  return this.http.get(`${this.baseURL}admins`)
}




 UpdateAdmin(id:number, user:any):Observable<any>{
  const formData = new FormData();
  formData.append("name", user.name);
  formData.append("mobile_number", user.mobile_number?.e164Number);
  formData.append("email", user.email);


  return this.http.patch<any>(`${this.baseURL}admins/${id}`, formData)
 
 }


 getAdminByID(uuid:number):Observable<any>{
  return this.http.get<any>(`${this.baseURL}admins/${uuid}`);
 }



deleteAdmin(uuid:number){
  return this.http.delete(`${this.baseURL}admins/${uuid}`,)

}

logout() {
  return this.http.get(`${this.baseURL}auth/logout`).subscribe(response=>{
    console.log(response);
  })
  // localStorage.removeItem('user');
  // this.userSubject.next(null);
  // this.router.navigate(['/account/login']);
}


createAdmin(user:any):Observable<any>{

  const formData = new FormData();

  formData.append("name", user.name);
  formData.append("role", user.role);
  formData.append("mobile_number", user.mobile_number?.e164Number);
  formData.append("email", user.email);
  formData.append("password", user.password);
  formData.append("password_confirmation", user.password_confirmation);
  return this.http.post<any>(`${this.baseURL}admins`, formData);

}


getAdminUser():Observable<any>{
  return this.http.get<any>(`${this.baseURL}admins`);
}


forgetPassword(body:any):Observable<any>{
  return this.http.post(`${this.baseURL}auth/forgot-password`, body)
 }



ResetPassword( body:any): Observable<any>{

  return this.http.post(`${this.baseURL}auth/reset-password`,body)
}


}
