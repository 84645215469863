import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/shared/services/auth.service";
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from "src/app/shared/services/encryption.service";

@Component({
  selector: 'app-login-image-one',
  templateUrl: './image-one.component.html',
  styleUrls: ['./image-one.component.scss']
})
export class LoginImageOneComponent implements OnInit {

  public show: boolean = false;

   
  public loginForm: FormGroup;

  public errorMessage: any;
  public loginData:any;


  constructor(
    private fb: FormBuilder,
     public router: Router,
      private authService :AuthService,
      private toastr: ToastrService,
      private cookieService: CookieService,
      private encryptionService: EncryptionService
      ) {


    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      user_type:["admin",Validators.required],
      device_name:["web",Validators.required],
      remember:[false] 
    });
    
  }

  ngOnInit() {}

 

  login(){


 
    if(this.loginForm.valid){}{
      localStorage.removeItem('user');
    this.authService.LoginUser(this.loginForm.value).subscribe(response=>{
  if(response.success=true){
    this.toastr.success(response.message,'', {
      timeOut: 3000,

    });
    localStorage.setItem('user', JSON.stringify( response.data.token));
    this.router.navigate(["/dashboard/ecommerce"]);
     const userDetails = { id: response.data.admin.id, name: response.data.admin.name, email: response.data.admin.email };

     this.cookieService.set('userDetails', JSON.stringify(userDetails));
 


  }
  else{
    this.toastr.error(response.error.message);

  }
})
    }
  }





  showPassword(){
    this.show = !this.show
  }


}

 



