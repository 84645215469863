import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {CountryService} from '../../../shared/services/country.service'

declare var require
const Swal = require('sweetalert2')


@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})


export class CountryComponent {



  countryData:any;
  public countryForm: FormGroup;
  public EditCountryForm:FormGroup;
isEdit:boolean;
  constructor(private fb: FormBuilder,private countryService:CountryService,){
 


    this.countryForm = this.fb.group({
    name: ["", Validators.required],
    code:[''],
    continent:[""]
    
    });
  


    this.EditCountryForm = this.fb.group({
      id:[''],
     name: ["", Validators.required],
     code:[''],
     continent:[""]
    });


  };

  ngOnInit():void{

    this.getCountry();
    this.isEdit=false;

    }






  CreateCountry(){
  
    return this.countryService.createCountry(this.countryForm.value).subscribe(
      response=>{
       
        
     if(response.success){
      Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        title: `${response.message}`,
        icon: 'success'
      });
   
        this.getCountry();
      this.countryForm.reset();

  
  

     }
      },(errors)=>{
        Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
  
          title: errors.error.message,
          icon: 'error'
        });

      }
   

    )
             
};    


 getCountry(){
  return this.countryService.getCountry().subscribe(response=>{
  
  this.countryData=response.data;
  console.log(this.countryData)
  
   })
   };


getCountrybyid(id:number){
  this.isEdit=true;
  return this.countryService.getCountryByID(id)
  .subscribe(response=>{
    if(response.success){
   
      this.editCountry(response.data)

    }

  } ,(errors)=>{
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,

      title: errors.error.message,
      icon: 'error'
    });

  }
  
  
  )
};



editCountry(country:any){
  this.EditCountryForm.patchValue({
    id:country.id,
     name:country.name,
     code:country.code,
     continent:country.continent
 
  })
  };

  
  UpdateCountry(){
     return this.countryService.UpdateCountry(this.EditCountryForm.value.id,this.EditCountryForm.value).subscribe(
      
      response=>{
       
        
        if(response.success){
          this.isEdit=false;
         Swal.fire({
           toast: true,
           position: 'top-end',
           showConfirmButton: false,
           timer: 3000,
           timerProgressBar: false,
           title: `${response.message}`,
           icon: 'success'
         });
      
           this.getCountry();
         this.EditCountryForm.reset();
   
     
     
   
        }
         },(errors)=>{
           Swal.fire({
             toast: true,
             position: 'top-end',
             showConfirmButton: false,
             timer: 3000,
             timerProgressBar: false,
     
             title: errors.error.message,
             icon: 'error'
           });
   
         }
    
    )
    };


delete_country(id:number){
Swal.fire({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, delete it!'
}).then((result) => {
  if (result.isConfirmed) {
this.countryService.deleteCountry(id)
    .subscribe(data=>{
this.getCountry();
    })

    Swal.fire(
      'Deleted!',
      'Your file has been deleted.',
      'success',
      
    )
  }
})

};
}
