import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
 
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      headTitle1: "General",
    },
    {
      title: "Dashboards",
      icon: "home",
      type: "link",
      path: "/dashboard/ecommerce",
      active: true,
       
    },
  
    {
      headTitle1: "Product Management",

    },
 
    {
      title: "Products",
      type: "sub",
      icon: "ecommerce",
      active: false,
      children: [
        { path: "/product/product/list", title: "Product-list ", type: "link" },
         { path: "/product/product-category", title: "Product Category", type: "link" },
      // { path: "/product/add-product", title: "Add product ", type: "link" },
     
      ],
    },

    {
      headTitle1: "Order  Management",

    },

    {
      title: "Orders",
      type: "sub",
      icon: "ecommerce",
      active: false,
      children: [
    
        { path: "/order/order", title: "Order History", type: "link" },
        
      ],
    },

    {
      title: "Equiry",
      type: "sub",
      icon: "ecommerce",
      active: false,
      children: [
    
        { path: "/order/enquiry", title: "Enquiry List", type: "link" },
        
      ],
    },


    {
      title: "Quatation",
      type: "sub",
      icon: "ecommerce",
      active: false,
      children: [
    
        { path: "/order/quatation", title: "Price Quatation", type: "link" },
        
      ],
    },


  
    {
      headTitle1: "Account Management",
    },
    {
      title: "Businesses",
      icon: "user",
      type: "sub",
      active: false,
      children: [
        { path: "/user/businesses", title: "All Businesses", type: "link" },
        { path: "/user/business-categories", title: "Business Categories", type: "link" },
      ],
    },
    {
      title: "Administrator",
      icon: "user",
      type: "sub",
      active: false,
      children: [
        { path: "/user/add-user", title: "Add User", type: "link" },
      
      ],
    },
   
    {
      headTitle1: "Content Management",
    },
    {
      title: "Home",
      icon: "layout",
      type: "sub",
      active: false,
      children: [

         { path: "/cms/banner", title: "Banner", type: "link" },
         { path: "/cms/advert", title: "Advert", type: "link" },
       
      ],
    },

    {
      headTitle1: " Miscellaneous",
    },
    {
      title: "Countries",
      icon: "map-marker",
      path: "/country",
      type: "link",
    },


    {
      title: "Chat",
      icon: "chat",
      type: "link",
      path:"chat",
      active: false,
   
    },

 
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
