import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private http:HttpClient) { }

  baseURL='https://api.caistt.com/api/v1/';

  getCountry():Observable<any>{
  
    return this.http.get(`${this.baseURL}countries`)
  }
  
    createCountry(countryInfo:any):Observable<any>{
    console.log(countryInfo)
      const body=JSON.stringify(countryInfo);

      return this.http.post(`${this.baseURL}countries`,body)
    }
  
  
    deleteCountry(id:any):Observable<any>{
      return this.http.delete(`${this.baseURL}countries/${id}`)
  
    }
  
  
    UpdateCountry(id:number, country:any):Observable<any>{
      const body=JSON.stringify(country);

      return this.http.put(`${this.baseURL}countries/${id}`, body)
     
     }
    
  
     getCountryByID(id:number):Observable<any>{
      return this.http.get<any>(`${this.baseURL}countries/${id}`);
     }
    

}
