<app-breadcrumb [title]="'Country'" [items]="[ 'Micellenous']" [active_item]="'Country'"></app-breadcrumb>


<div class="row col-md-12">
  <div class=" col-md-6">
    <form method="post" [formGroup]="countryForm" (ngSubmit)='CreateCountry()' *ngIf="!isEdit">
  
      <div class="row">
  
        <div class="col-xl-12">
          <div class="card mb-4">
            <div class="card-header">
  
              <div class="float-start">
            <h4>     Country Details</h4>
          
              </div>
             
                  </div>
            <div class="card-body">
  
              <div class="row gx-3 mb-3">
                <div class="col-md-12">
                  <label class="small mb-1" for="name">Name</label>
                  <input class="form-control" id="name" type="text" placeholder="Please enter name "
                    formControlName='name'>
                </div>
             
              </div>
              <div class="col-sm-12 col-md-12 mb-3">
                <label class="small mb-1" for="name">Continent</label>
  
                <select class="form-select" aria-label="Default select example" formControlName="continent">
                    <option >Select continent</option>
                    <option value="africa">Africa</option>
                    <option value="asia">Asia</option>
                   
                  </select>
              
    
            </div>
              
              <button type="submit" class="btn btn-primary" >Save </button>
            </div>
          </div>
        </div>
  
      </div>
    </form>

    <form method="post" [formGroup]="EditCountryForm" (ngSubmit)='UpdateCountry()' *ngIf="isEdit">

      <div class="row">

        <div class="col-xl-12">
          <div class="card mb-4">
            <div class="card-header">

              <div class="float-start">
            <h4>    Edit Country Details</h4>
          
              </div>
            
            </div>
            <div class="card-body">
              <div class="row gx-3 mb-3">
                <div class="col-md-12">
                  <label class="small mb-1" for="name">Name</label>
                  <input class="form-control"  type="text" placeholder="Please enter country name "formControlName='name'>
                </div> 
              </div>

            
              <div class="col-sm-12 col-md-12 mb-3">
                <label class="small mb-1" for="name">Continent</label>

                <select class="form-select" aria-label="Default select example" formControlName="continent">
                    <option >Select continent</option>
                    <option value="africa">Africa</option>
                    <option value="asia">Asia</option>
                   
                  </select>
              
    
            </div>
              
              <button type="submit" class="btn btn-primary" >Update </button>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>

  <div class="col-md-6">
    <div class="card">
     
      <div class="table-responsive signal-table">
        <table class="table table-hover">
          <thead>
            <tr>
              
              <th scope="col">Name</th>
              <th scope="col">Code</th>
              <th scope="col">Continent</th>
              <th scope="col">Action</th>
              
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let country of countryData;">
              <td>{{country.name}}</td>
              <td>{{country.code}}</td>
              <td>{{country.continent}}</td>
              <td>

                <span class="fa fa-edit mx-2"  (click)="getCountrybyid(country.id)"></span>
                <span><i class="fa fa-trash-o" (click)='delete_country(country.id)' ></i></span>
              </td>
            </tr>
           
           
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>




